<template>
  <div id="div-treatment-history">
    <span class="d-flex justify-content-end">
      <b-button
        pill
        variant="link"
        class="text-danger"
        size="sm"
        @click="$emit('exit')"
      >
        {{ t('Button Close') }}
      </b-button>
    </span>
    <center>
      <b-img
        :src="require('@/assets/images/logo/sp_logo_white.png')"
        height="30"
        alt="Sriphat"
      />
    </center>
    <h6 class="text-center text-small mb-1">
      <span class="text-primary">SRIPHAT </span>
      <span class="font-weight-semibold text-success">PHR</span>
    </h6>
    <hr>
    <h4 class="text-success mb-1">
      {{ t('title.Medical History') }}
    </h4>
    <div
      v-if="medical === null"
      class="text-center"
    >
      <b-spinner variant="success" />
    </div>
    <div
      v-else
    >
      <div class="d-flex justify-content-between">
        <h5 class="text-primary">
          {{ $helpers.getFullDate(medical.enrollDetail.enrollDate, $i18n.locale) }}
        </h5>
        <span
          v-if="medical.enrollDetail.typeCome === ''
            || medical.enrollDetail.typeCome === null
            || medical.enrollDetail.typeCome === 'W'"
          class="text-success"
        >
          {{ t('medical.W') }}
        </span>
        <span
          v-else-if="medical.enrollDetail.typeCome === 'F'"
          class="text-success"
        >
          {{ t('medical.F') }}
        </span>
        <span
          v-else-if="medical.enrollDetail.typeCome === 'IPD'"
          class="text-success"
        >
          {{ t('medical.IPD') }}
        </span>
      </div>
      <!-- Right -->
      <b-card
        no-body
        border-variant="primary"
        class="mb-1"
      >
        <b-card-text class="p-1">
          <h6>
            {{ t('payor') }}<br>
            <small class="pl-1">
              {{ medical.enrollDetail.payor }}
            </small>
          </h6>
          <h6>
            {{ t('clinic') }}<br>
            <small class="pl-1">
              {{ medical.enrollDetail.clinicName }}
            </small>
          </h6>
          <h6>
            {{ t('doctor') }}<br>
            <small class="pl-1">
              {{ medical.enrollDetail.doctorName }}
            </small>
          </h6>
        </b-card-text>
      </b-card>
      <!-- Chief Complaint -->
      <b-card
        no-body
        border-variant="warning"
        class="mb-1"
      >
        <b-card-text class="pt-1 pr-1 pl-1 pb-0">
          <h6>
            {{ t('medical.reason') }}
          </h6>
          <p class="pl-1">
            {{ medical.enrollDetail.chifComplaint === '' || medical.enrollDetail.chifComplaint === null ? '-' : medical.enrollDetail.chifComplaint }}
          </p>
        </b-card-text>
      </b-card>
      <!-- ICD-10 -->
      <b-card
        v-if="medical.icdDescs.length > 0"
        no-body
        border-variant="info"
        class="mb-1"
      >
        <b-card-text
          v-for="(data, index) in medical.icdDescs"
          :key="index"
          class="pt-1 pr-1 pl-1 pb-0"
        >
          <h6>
            {{ $i18n.locale === 'th' ? data.typeICDTH : data.typeICD }}
          </h6>
          <p class="pl-1">
            {{ $i18n.locale === 'th' ? data.icdDescTH.replaceAll('ไม่ระบุรายละเอียด','') : data.icdDesc.replaceAll('\\, unspecified','') }}
          </p>
        </b-card-text>
      </b-card>
      <!-- Vital Sign -->
      <b-card
        v-if="medical.vitalSignAndBodyMass.pluse !== 0 && medical.vitalSignAndBodyMass.bp !== null"
        no-body
        border-variant="primary"
        class="mb-1"
      >
        <b-card-text class="pt-1 pr-1 pl-1 pb-0">
          <h6 class="font-weight-normal">
            <feather-icon
              class="mr-10 text-warning"
              icon="ThermometerIcon"
            />
            {{ t('medical.temp') }} <span>{{ medical.vitalSignAndBodyMass.temp }}  <sup>o</sup>C</span>
          </h6>

          <h6 class="font-weight-normal">
            <feather-icon
              class="mr-10 text-success"
              icon="HeartIcon"
            />
            {{ t('medical.pulse') }} <span>{{ medical.vitalSignAndBodyMass.pulse }} bpm</span>
          </h6>

          <h6 class="font-weight-normal">
            <feather-icon
              class="mr-15 text-danger"
              icon="DropletIcon"
            />
            {{ t('medical.bp') }} <span>{{ medical.vitalSignAndBodyMass.bp }} mm/Hg</span>
          </h6>

          <h6 class="font-weight-normal">
            <feather-icon
              class="mr-15 text-info"
              icon="UserIcon"
            />
            {{ t('medical.bmi') }} <span>{{ medical.vitalSignAndBodyMass.bmi }}</span>
          </h6>
        </b-card-text>
      </b-card>
      <!-- Drugs -->
      <b-card
        v-if="medical.orderDrugs.length > 0"
        no-body
        border-variant="success"
        class="mb-1"
      >
        <b-card-text class="pt-1 pr-1 pl-1 pb-0">
          <h6>
            <strong>{{ t('medical.drug') }}</strong>
          </h6>
          <div
            v-for="(data, index) in medical.orderDrugs"
            :key="index"
            class="mb-1"
          >
            <h6 class="text-success">
              <feather-icon
                class="text-warning mr-25"
                icon="ImageIcon"
                @click="loadDrugImage(data)"
              />{{ data.drugName }}
            </h6>
            <span
              v-if="data.genericName"
              class="text-primary pl-1"
            >
              {{ data.genericName }}
            </span>
            <span v-if="data.description">
              {{ data.description }}
            </span>
            <span v-if="data.description">
              {{ data.drugHowTo }}
            </span>
          </div>
        </b-card-text>
      </b-card>
      <!-- Lab -->
      <b-card
        v-if="labResults.length > 0"
        no-body
        border-variant="danger"
        class="mb-1"
      >
        <b-card-text class="pt-1 pr-1 pl-1 pb-0">
          <h6>
            <strong>{{ t('medical.lab result') }}</strong>
          </h6>
          <div class="text-mute d-flex justify-content-end">
            <b-form-checkbox
              v-model="rangeShow"
              value="1"
              unchecked-value="0"
            >
              Range
            </b-form-checkbox>
          </div>
          <div
            v-for="(labResult, index) in labResults"
            :key="index"
            class="mb-1"
          >
            <div
              v-for="(item, index1) in labResult"
              :key="index1"
              class="mb-1"
            >
              <small class="text-primary">
                {{ item.name }}
              </small>
              <b-row
                v-for="(data, index2) in item.mainChildLab"
                :key="index2"
                class="p-0"
              >
                <b-col
                  cols="8"
                  class="pb-0 pl-1"
                >
                  <small>{{ data.vistD_TestName }}</small>
                </b-col>
                <b-col
                  cols="4"
                  :class="`pb-0 ${getStatus(data.vistD_ResultStatus)}`"
                >
                  <small>{{ data.vistD_TestData }}</small>
                </b-col>
                <b-col
                  v-if="rangeShow === '1' && data.vistD_Range !== ''"
                  cols="12"
                  class="pt-0 pb-1 text-right"
                >
                  <small>
                    ( {{ data.vistD_Range }} )
                  </small>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <!-- Radiology -->
      <b-card
        v-if="radiologyResult.length > 0"
        no-body
        border-variant="info"
        class="mb-1"
      >
        <b-card-text class="pt-1 pr-1 pl-1 pb-0">
          <h6>
            <strong>{{ t('medical.radiology result') }}</strong>
          </h6>
          <div
            v-for="(item, index) in radiologyResult"
            :key="index"
            style="white-space: pre-wrap; color:gray"
          >
            <p>
              {{ item.report.trim().replaceAll('br>', '\n') }}
            </p>
          </div>
        </b-card-text>
      </b-card>
      <span class="d-flex justify-content-end">
        <b-button
          pill
          variant="link"
          class="text-danger"
          size="sm"
          @click="$emit('exit')"
        >
          {{ t('Button Close') }}
        </b-button>
      </span>
    </div>
    <b-modal
      ref="drugModal"
      :hide-footer="true"
      centered
      size="sm"
    >
      <b-card-text>
        <h4 class="text-success text-center">
          {{ drugName }}
        </h4>
        <h5
          v-if="drugGName"
          class="text-primary text-center"
        >
          {{ drugGName }}
        </h5>
        <center>
          <b-img
            v-if="drugImage"
            rounded
            :src="drugImage"
            class="mt-1"
            height="200"
            alt="Drug image"
          />
          <h1
            v-else
            class="m-5"
          >
            No Image
          </h1>
        </center>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  props: {
    txn: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
      patientView: JSON.parse(localStorage.getItem('patientTempData')),
      // txn: this.$route.params.id,
      medical: null,
      drugName: '',
      drugImage: '',
      drugGName: '',
      labResults: [],
      radiologyResult: [],
      rangeShow: '0',
      labLoadings: false,
      radiologyLoadings: false,
    }
  },
  created() {
    this.loadMedicalDetail()
    this.getRadiologyResult()
  },
  methods: {
    loadMedicalDetail() {
      this.showLoadingMore = true
      this.medical = null
      this.$http({
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/CustomerEMR/0477749/55345005',
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/CustomerEMR/${this.patientInfo.hn}/${this.txn}`,
        // url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/CustomerEMR/${this.patientInfo.hn}/${this.txn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.medical = data.customer_Health_Record_Model
        this.getLabHistory()
        this.showLoadingMore = false
      }).catch(error => {
        console.log('loadMedicalDetail', error)
        this.showLoadingMore = false
      })
    },
    loadDrugImage(drugData) {
      this.drugImage = ''
      this.drugName = drugData.drugName
      this.drugGName = drugData.genericName
      this.$http({
        url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/ImageDrug',
        method: 'POST',
        data: {
          drugCode: drugData.drugCode,
        },
      }).then(({ data }) => {
        if (data) {
          this.drugImage = `data:image/jpeg;base64, ${data}`
        }
        this.$refs.drugModal.show()
      }).catch(error => {
        console.log('loadFrugImage', error)
      })
    },
    getLabHistory() {
      this.labLoadings = true
      this.$http({
        method: 'post',
        url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/LabHistory',
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/LabHistory',
        data: {
          HN: this.patientInfo.hn,
          Date: this.medical.enrollDetail.enrollDate.slice(0, 10),
        },
      }).then(({ data }) => {
        const filteredLabs = data.filter(labs => (labs.txn.includes(this.txn)))
        // console.log('filteredLabs', data, filteredLabs)
        for (let i = 0; i < filteredLabs.length; i += 1) {
          if (this.txn === filteredLabs[i].txn) {
            this.getLabResult(filteredLabs[i].barcode)
          }
        }
        this.labLoadings = false
      }).catch(error => {
        console.log('getLabHistory', error)
      })
    },
    getLabResult(barcode) {
      this.$http({
        method: 'post',
        url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/LabDetailOfPatient',
        // url: 'http://phrapi.sriphat.com/api/Customer_Health_Record_/LabDetailOfPatient',
        data: {
          HN: this.patientInfo.hn,
          Barcode: barcode,
          Date: this.medical.enrollDetail.enrollDate.slice(0, 10),
        },
      }).then(({ data }) => {
        this.labResults.push(data)
      }).catch(error => {
        console.log('getLabResult', error)
      })
    },
    getStatus(status) {
      if (status === 'HI') return 'text-danger'
      if (status === 'LO') return 'text-warning'
      return ''
    },
    getRadiologyResult() {
      this.radiologyLoadings = true
      this.$http({
        method: 'get',
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/XrayResultByTXN/${this.txn}`,
        data: {},
      }).then(({ data }) => {
        this.radiologyResult = data
        this.radiologyLoadings = false
      }).catch(error => {
        console.log('getRadiologyResult', error)
      })
    },
  },
}
</script>
