<template>
  <div id="div-treatment-history">
    <profile-view-as v-if="patientView && patientView.idCard !== patientInfo.idCard" />
    <b-row>
      <b-col>
        <h4 class="text-primary">
          {{ t('title.Medical History') }}
          <br>
          <small>
            {{ t('title.Past Medical History') }}
          </small>
        </h4>
      </b-col>
      <!-- <b-col class="text-right">
        <b-form-checkbox
          v-model="drug"
          checked="false"
          name="check-button"
          switch
          inline
        >
          {{ t('Only have drug') }}
        </b-form-checkbox>
      </b-col> -->
    </b-row>

    <b-row
      v-if="medicalHistories.length === 0"
    >
      <b-col>
        <b-card
          class="text-center"
        >
          <b-spinner variant="primary" />
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-else
    >
      <b-col
        v-for="(data, index) in medicalHistories"
        :key="index"
        md="4"
        sm="6"
        cols="12"
      >
        <b-card
          no-body
          class="border-secondary"
        >
          <b-card-body class="pb-0">
            <b-row>
              <b-col>
                <h3>{{ $helpers.getFullDate(data.enrollDate, $i18n.locale) }}</h3>
              </b-col>
              <b-col
                cols="4"
                class="text-right"
              >
                <b-badge
                  v-if="data.numOfDrug > 0"
                  variant="info"
                >
                  {{ data.numOfDrug }}
                  <small v-if="data.numOfDrug > 1">
                    drugs
                  </small>
                  <small v-else>
                    drug
                  </small>
                </b-badge>
              </b-col>
            </b-row>

            <h5 class="text-nowrap">
              <feather-icon
                class="mr-25 text-danger"
                icon="MapPinIcon"
              />
              {{ $i18n.locale === 'th' ? data.clinicName : data.clinicNameENG }}
            </h5>
            <h5>
              <feather-icon
                class="mr-25 text-primary"
                icon="UserIcon"
              />
              {{ data.doctorName }}
            </h5>
            <h5>
              <feather-icon
                class="mr-25 text-success"
                icon="Navigation2Icon"
              />
              <span
                v-if="data.typeCome === ''
                  || data.typeCome === null
                  || data.typeCome === 'W'"
              >
                {{ t('medical.W') }}
              </span>
              <span v-else-if="data.typeCome === 'F'">
                {{ t('medical.F') }}
              </span>
              <span v-else-if="data.typeCome === 'IPD'">
                {{ t('medical.IPD') }}
              </span>
            </h5>
            <b-alert
              v-if="data.pdDesc"
              variant="primary"
              show
            >
              <div
                class="alert-body"
                style="white-space: nowrap;  width: 90%;  overflow: hidden;  text-overflow: ellipsis;"
              >
                {{ data.pdCode !== null && data.pdCode !== '' ? $i18n.locale === 'th' ? data.pdDescTH.replaceAll('ไม่ระบุรายละเอียด','') : data.pdDesc.replaceAll('\\, unspecified','') : '-' }}
              </div>
            </b-alert>
            <b-alert
              v-else
              variant="primary"
              show
            >
              <div
                class="alert-body"
                style="white-space: nowrap;  width: 90%;  overflow: hidden;  text-overflow: ellipsis;"
              >
                <span class=" text-hide">1</span>
              </div>
            </b-alert>
          </b-card-body>
          <b-card-footer
            class="text-light bg-primary pt-1 pb-1"
            @click="showMedicalModal(data.txn)"
          >
            {{ t('Button Detail') }}
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <div
      v-if="showLoadingMore"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <b-button
      v-if="medicalHistories.length > 0 && !showLoadingMore"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="loadMore"
    >
      <span class="align-middle">{{ t('Button More') }}</span>
      <feather-icon
        icon="MoreHorizontalIcon"
        class="ml-50"
      />
    </b-button>
    <b-modal
      id="medicalModal"
      ref="medicalModal"
      :hide-footer="true"
      :hide-header="true"
      centered
      size="xs"
    >
      <profile-medical-history-detail
        :txn="txn"
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ProfileMedicalHistoryDetail from '@/views/profile/ProfileMedicalHistoryDetail.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ProfileMedicalHistoryDetail,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
      patientInfo: JSON.parse(localStorage.getItem('patientData')),
    }
  },
  data() {
    return {
      patientView: JSON.parse(localStorage.getItem('patientTempData')),
      medicalHistories: [],
      page: 1,
      showLoadingMore: false,
      drug: false,
      txn: '',
    }
  },
  created() {
    this.medicalHistories = []
    this.loadMedicalList()
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-history' })
    },
    loadMore() {
      // eslint-disable-next-line radix
      this.page = parseInt(this.page) + 1
      this.showLoadingMore = true
      this.loadMedicalList()
    },
    loadMedicalList() {
      const pageIndex = (this.page - 1) * 5
      this.$http({
        url: `http://phrapi.sriphat.com/api/Customer_Health_Record_/listcustomerEMR/${this.patientInfo.hn}/${pageIndex}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        if (this.medicalHistories.length > 0) {
          for (let i = 0; i < data.enrollHistories.length; i += 1) {
            this.medicalHistories.push(data.enrollHistories[i])
          }
        } else {
          this.medicalHistories = data.enrollHistories
        }
        // console.log(this.medicalHistories)
        this.showLoadingMore = false
      }).catch(error => {
        console.log('loadMedicalList', error)
        this.showLoadingMore = false
      })
    },
    showMedicalModal(txn) {
      this.txn = txn
      this.$refs.medicalModal.show()
    },
    onCloseModal() {
      this.$refs.medicalModal.hide()
    },
  },
}
</script>
